import React from 'react';
import { withRouter, Link } from 'react-router-dom';

import { status } from './data/status.js';
import * as cards from './data/cards';
import { loadDict } from './dict/dict.js';
import './Review.css';

class Review extends React.Component {
    state = {
        setLabel: '...',
        card: null,
        dictEntries: [],
        showAnswer: false,
        done: false,
    }

    get id() {
        return this.props.match.params.id;
    }

    constructor(props) {
        super(props);
        this.dict = loadDict();
    }

    async componentDidMount() {
        const setLabel = this.id ? (await cards.getSet(this.id)).label : 'All';
        this.setState({
            setLabel,
        });
        await this.nextCard();
    }

    async showAnswer() {
        this.setState({
            showAnswer: true,
        });
    }

    async recordStatus(status) {
        if (!this.state.showAnswer) {
            return;
        }
        const card = this.state.card;
        this.setState({
            card: null,
            showAnswer: false,
        });
        const incrementMs = calculateIncrementMs(status, this.state.card.lastStatus);
        await cards.incrementReview(card, incrementMs, status);
        await this.nextCard();
    }

    async nextCard() {
        const card = await cards.nextCard(this.id);
        if (!card) {
            this.setState({
                done: true,
            });
            return;
        }
        const dict = await this.dict;
        const dictEntries = dict.traditional(card.word) ?? [];
        this.setState({
            card,
            dictEntries,
            showAnswer: false,
        });
    }

    render() {
        const header = (
            <div className="page-header">
                <h1 className="page-title">
                    <Link to='/' className="back">Sets</Link> &gt;
                    Review: {this.state.setLabel}
                </h1>
            </div>
        );
        if (this.state.done) {
            return (
                <div>
                    {header} 
                    You're done for now; yay! <Link to='/'>Back to home</Link>
                </div>
            );
        }
        if (!this.state.card) {
            return (
                <div>
                    {header}
                </div>
            );
        }
        const card = this.state.card;

        const goodInterval = (card.lastStatus === status.GOOD || card.lastStatus === status.EASY) ? '1d' : '10m';

        const dictEntries = this.state.dictEntries.map((entry) =>
            <div key={entry.definition} className="definition">
                <div>{entry.pronunciation}</div>
                <div>{entry.definition.join(' / ')}</div>
            </div>
        );

//https://www.mdbg.net/chinese/dictionary?page=worddict&wdqb=%E6%B7%B1%E6%B7%B5
        const word = this.state.showAnswer ? (
            <div className="word">
                <a href={`https://www.mdbg.net/chinese/dictionary?page=worddict&wdrst=1&wdqb=${card.word}`}
                   target="_blank"
                   rel="noopener noreferrer"
                   className="dict-link">
                    {card.word}
                </a>
            </div>
        ) : (
            <div className="word">{card.word}</div>
        );

        const definition = this.state.showAnswer ? (
            <div>
                {dictEntries}
            </div>
        ) : '';

        const buttons = this.state.showAnswer ? (
            <div>
                <button onClick={() => this.recordStatus(status.AGAIN)}>(1m) Again</button>
                <button onClick={() => this.recordStatus(status.GOOD)}>({goodInterval}) Good</button>
                <button onClick={() => this.recordStatus(status.EASY)}>(4d) Easy</button>
            </div>
        ) : (
            <div>
                <button onClick={() => this.showAnswer()}>Show Answer</button>
            </div>
        )

        return (
            <div>
                {header}
                {word}
                <div className="buttons">{buttons}</div>
                {definition}
            </div>
        );
    }
}

function calculateIncrementMs(currStatus, prevStatus) {
    const randomPerturbation = Math.floor(Math.random() * 20000);
    if (currStatus === status.AGAIN) {
        return 40 * 1000 + randomPerturbation;
    } else if (currStatus === status.GOOD) {
        if (prevStatus === status.GOOD || prevStatus === status.EASY) {
            return 20 * 60 * 60 * 1000 + randomPerturbation;
        } else {
            return 10 * 60 * 1000 + randomPerturbation;
        }
    } else {
        return 4 * 24 * 60 * 60 * 1000 + randomPerturbation;
    }
}

export default withRouter(Review);
