import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as firebase from 'firebase/app';

const firebaseConfig = {
  apiKey: "AIzaSyDiFaSePBncOeUypMF50JNFhOKUY5GZReQ",
  authDomain: "uxie-ev.firebaseapp.com",
  databaseURL: "https://uxie-ev.firebaseio.com",
  projectId: "uxie-ev",
  storageBucket: "uxie-ev.appspot.com",
  messagingSenderId: "261226699579",
  appId: "1:261226699579:web:e9184d7916567cc55eb51e"
};
firebase.initializeApp(firebaseConfig);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
