import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Link,
  Switch,
  Route,
} from "react-router-dom";

import * as firebase from 'firebase/app';
import 'firebase/auth';

import Add from './Add';
import Edit from './Edit';
import List from './List';
import Review from './Review';
import LoginButton from './LoginButton';
import LogoutButton from './LogoutButton';
import './App.css';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      authUser: null,
      initialized: false,
    };
  }

  componentDidMount() {
    this.unsubscribe = firebase.auth().onAuthStateChanged((authUser) => {
      this.setState({
        authUser,
        initialized: true,
      })
    });
  }

  componentWillUnmount() {
    // this.unsubscribe();
  }

  render() {
    if (!this.state.initialized) {
      return '';
    }
    if (!this.state.authUser) {
      return (
        <LoginButton />
      );
    }
    return (
      <div>
        <Router>
          <div className="top-nav">
            <Link to='/' className="title">Uxie</Link>
            <LogoutButton />
          </div>
          <div className="content">
            <Switch>
              <Route path="/add">
                <Add></Add>
              </Route>
              <Route path="/edit/:id">
                <Edit></Edit>
              </Route>
              <Route path="/review/:id">
                <Review></Review>
              </Route>
              <Route path="/review">
                <Review></Review>
              </Route>
              <Route path="/">
                <List></List>
              </Route>
            </Switch>
          </div>
        </Router>
      </div>
    );
  }
}

export default App;
