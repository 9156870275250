import React, { Component } from 'react';
import * as firebase from 'firebase/app';
import 'firebase/auth';

class LoginButton extends Component {
    login() {
        firebase.auth().signInWithPopup(new firebase.auth.GoogleAuthProvider());
    }

    render() {
        return (
            <button onClick={() => this.login()}>Login</button>
        );
    }
}

export default LoginButton;
