import React from 'react';
import { withRouter, Link } from 'react-router-dom';

import * as cards from './data/cards';
import './Edit.css';

class Edit extends React.Component {
  state = {
    loaded: false,
    label: '',
    cards: [],
  }

  get id() {
    return this.props.match.params.id;
  }

  constructor(props) {
    super(props);
    this.editCardSet = this.editCardSet.bind(this);
    this.labelInput = React.createRef();
    this.cardsInput = React.createRef();
  }

  async componentDidMount() {
    const set = await cards.getSet(this.id);
    if (!set) {
      this.props.history.push('/');
      return;
    }
    this.setState({
      loaded: true,
      label: set.label,
      cards: set.cards,
    });
  }

  async editCardSet(event) {
    event.preventDefault();

    const label = this.labelInput.current.value.trim();
    if (!label) {
      return;
    }
    const words = this.cardsInput.current.value.split('\n').map(l => l.trim()).filter(l => !!l);
    if (!words.length) {
      return;
    }

    await cards.editSet(this.id, label, words);

    this.props.history.push('/');
  }

  async deleteCardSet() {
    if (window.confirm('Are you sure?')) {
      await cards.deleteSet(this.id);
      this.props.history.push('/');
    }
  }

  render() {
    if (!this.state.loaded) {
      return null;
    }
    const cards = this.state.cards.map((c) => c.word).join('\n');
    return (
      <div>
        <div className="page-header">
          <h1 className="page-title"><Link to='/' className="back">Sets</Link> &gt; Edit Set</h1>
          <div>
            <button onClick={() => this.deleteCardSet()}>Delete set</button>
          </div>
        </div>
        <form onSubmit={this.editCardSet}>
          <label>
            <div className="form-label">Card Set Label</div>
            <input name="label" className="label" ref={this.labelInput} autoFocus="autoFocus" defaultValue={this.state.label} />
          </label>
          <label>
            <div className="form-label">Cards (1 per line)</div>
            <textarea name="cards" className="cards" ref={this.cardsInput} defaultValue={cards}></textarea>
          </label>
          <div className="form-actions">
            <button>Save</button>
          </div>
        </form>
      </div>
    );
  }
}

export default withRouter(Edit);
