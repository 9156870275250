import React from 'react';
import { withRouter, Link } from 'react-router-dom';

import * as cards from './data/cards';
import './Add.css';

class Add extends React.Component {
  constructor(props) {
    super(props);
    this.addCardSet = this.addCardSet.bind(this);
    this.labelInput = React.createRef();
    this.cardsInput = React.createRef();
  }

  async addCardSet(event) {
    event.preventDefault();

    const label = this.labelInput.current.value.trim();
    if (!label) {
      return;
    }
    const words = this.cardsInput.current.value.split('\n').map(l => l.trim()).filter(l => !!l);
    if (!words.length) {
      return;
    }

    await cards.addSet(label, words);

    this.props.history.push('/');
  }

  render() {
    return (
      <div>
        <div className="page-header">
          <h1 className="page-title"><Link to='/' className="back">Sets</Link> &gt; Add Set</h1>
        </div>
        <form onSubmit={this.addCardSet}>
          <label>
            <div className="form-label">Card Set Label</div>
            <input name="label" className="label" ref={this.labelInput} autoFocus="autoFocus" />
          </label>
          <label>
          <div className="form-label">Cards (1 per line)</div>
            <textarea name="cards" className="cards" ref={this.cardsInput}></textarea>
          </label>
          <div className="form-actions">
            <button>Submit</button>
          </div>
        </form>
      </div>
    );
  }
}

export default withRouter(Add);
