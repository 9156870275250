import React from 'react';
import {
  Link
} from "react-router-dom";

import * as cards from './data/cards';
import './List.css';

class List extends React.Component {
  state = {
    sets: [],
  }

  async componentDidMount() {
    const sets = await cards.listSets();
    this.setState({
      sets,
    });
  }

  render() {
    const sets = this.state.sets.map((set) =>
      <tr key={set.id}>
        <td><Link to={`/review/${set.id}`} className="cell-link">{set.label}</Link></td>
        <td><Link to={`/review/${set.id}`} className="cell-link">{set.numUnreviewed}</Link></td>
        <td><Link to={`/edit/${set.id}`} className="cell-link link-edit">Edit</Link></td>
      </tr>
    );
    return (
      <div>
        <div className="page-header">
          <h1 className="page-title">Sets</h1>
          <Link to="/review" className="header-action">Review All</Link>
          <Link to="/add" className="header-action">New Set</Link>
        </div>
        <table className="sets">
          <thead>
            <tr>
              <th className="col-label">Set</th>
              <th className="col-cards">Cards</th>
              <th className="col-edit">Edit</th>
            </tr>
          </thead>
          <tbody>
            {sets}
          </tbody>
        </table>
      </div>
    );
  }
}

export default List;
