import React, { Component } from 'react';
import * as firebase from 'firebase/app';
import 'firebase/auth';

class LogoutButton extends Component {
    logout() {
        firebase.auth().signOut();
    }

    render() {
        return (
            <div>
                <button onClick={() => this.logout()}>Logout</button>
            </div>
        );
    }
}

export default LogoutButton;
