import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import { minBy, sortBy } from 'lodash';
import { status } from './status';

const MAX_REVIEWED_BACKLOG = 5;

// Bump the current timestamp so Again on small sets gives you the card again.
const TIME_IN_FUTURE_REVIEW_NOW = 65 * 1000;

export async function addSet(label, words) {
    const now = Date.now();

    const uniqueWords = [...new Set(words)];
    const cards = uniqueWords.map((w) => ({
        word: w,
        nextReviewMs: now,
        lastStatus: status.NOT_REVIEWED,
    }));
    await db().child('sets').push({
        label,
        cards,
    });
}

export async function editSet(setId, label, words) {
    const now = Date.now();

    const uniqueWords = [...new Set(words)];
    const existing = await getSet(setId);

    const updatedWordSet = new Set(words);
    const existingCards = Object.values(existing.cards).filter((c) => updatedWordSet.has(c.word));

    const existingWordSet = new Set(existingCards.map((c) => c.word));
    const newCards = uniqueWords.filter((w) => !existingWordSet.has(w)).map((w) => ({
        word: w,
        nextReviewMs: now,
        lastStatus: status.NOT_REVIEWED,
    }));

    await db().child(`sets/${setId}`).update({
        label,
        cards: [
            ...existingCards,
            ...newCards,
        ],
    });
}

export async function deleteSet(setId) {
    await db().child(`sets/${setId}`).remove();
}

export async function listSets() {
    const now = Date.now() + TIME_IN_FUTURE_REVIEW_NOW;

    const snapshot = await db().child('sets').once('value');
    const sets = Object.entries(snapshot.val() ?? {}).map(([id, val]) => ({
        id,
        label: val.label,
        numUnreviewed: Object.values(val.cards).filter((card) => card.nextReviewMs < now).length,
    }));
    return sortBy(sets, (s) => s.label);
}

export async function getSet(setId) {
    const snapshot = await db().child(`sets/${setId}`).once('value');
    return snapshot.val();
}

export async function incrementReview(card, incrementMs, status) {
    const snapshot = await db().child(`sets/${card.setId}/cards`).orderByChild('word').equalTo(card.word).once('value');
    const key = Object.keys(snapshot.val())[0];
    await db().child(`sets/${card.setId}/cards/${key}`).update({
        nextReviewMs: Date.now() + incrementMs,
        lastStatus: status,
    });
}

export async function nextCard(setId) {
    const cards = setId ? Object.values((await getSet(setId)).cards).map((c) => ({setId, ...c})) : await getAllCards();
    if (!cards.length) {
        return null;
    }

    const now = Date.now() + TIME_IN_FUTURE_REVIEW_NOW;

    const reviewedCardsBacklog = cards.filter((c) => c.lastStatus !== status.NOT_REVIEWED && c.nextReviewMs < now);
    if (reviewedCardsBacklog.length > MAX_REVIEWED_BACKLOG) {
        return minBy(reviewedCardsBacklog, (c) => c.nextReviewMs);
    }

    const card = minBy(cards, (c) => c.nextReviewMs);
    if (card.nextReviewMs <= now) {
        return card;
    }

    return null;
}

async function getAllCards() {
    const setRef = await db().child('sets').once('value');
    const sets = Object.entries(setRef.val());
    return sets.flatMap(([id, set]) => Object.values(set.cards).map((c) => ({setId: id, ...c})));
}

function db() {
    return firebase.database().ref(`users/${firebase.auth().currentUser.uid}`);
}
